import DatePicker from 'react-datepicker'
import sumBy from 'lodash/sumBy'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Col, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import OrganizationSelect from '../common/OrganizationSelect'
import { CompletedConsultations_consultations } from '../../hasura/graphQlQueries/types/CompletedConsultations'
import { ConsultationsState, consultationsSelector, fetchCompletedConsultationsAction } from '../../hasura/slices/consultations'
import { Option, numberWithCommas, requestedImagesCount } from '../../lib/helpers'
import { OrganizationsState, fetchOrganizationsAction, organizationsSelector } from '../../hasura/slices/organizations'
import { QueryName } from '../../hasura/queryNames'
import { Row, Table, NarrowCell, defaultColumn, HeaderCell, Header, isColumn, Cell } from '../../components/common/Table'
import { parseDate } from '../../lib/timeHelpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

const MAX_ROWS = 500

export default function Profitability() {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { completedConsultations, isQuerying }: ConsultationsState = useSelector(consultationsSelector)
  const { organizations }: OrganizationsState = useSelector(organizationsSelector)

  const [organization, setOrganization] = useState<Option | null>()
  const [chartData, setChartData] = useState<any[]>([])
  const [filteredConsultations, setFilteredConsultations] = useState<CompletedConsultations_consultations[]>([])
  const [startDate, setStartDate] = useState(moment().startOf('year').toDate())
  const [endDate, setEndDate] = useState(moment().toDate())

  useEffect(() => {
    if (organizations.length || !user) return

    dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
  }, [organizations, user])

  useEffect(() => {
    if (!user) return

    dispatch(
      fetchCompletedConsultationsAction(
        accessToken,
        [user.organization.enterprise.id],
        startDate.toLocaleDateString('en-US'),
        endDate.toLocaleDateString('en-US')
      )
    )
  }, [startDate, endDate, user])

  useEffect(() => {
    const filteredConsultations = completedConsultations.filter(
      (c) => !organization || c.case.dicom_server?.organization?.id === organization?.value
    )
    const groupedByWeek = groupBy(filteredConsultations, (c) => `${moment(c.completed_at).week()}-${moment(c.completed_at).year()}`)
    const chartData = orderBy(Object.keys(groupedByWeek), (key: any) => moment(groupedByWeek[key][0].completed_at)).map((key) => {
      const name = moment(groupedByWeek[key][0].completed_at).startOf('week').format('MMM D')
      const revenue = sumBy(groupedByWeek[key], (c) => c.price_amount)
      const cost = sumBy(groupedByWeek[key], (c) => c.receiving_vet_pay_amount)
      return { name, revenue, cost, profit: revenue - cost }
    })
    setChartData(chartData)
    setFilteredConsultations(filteredConsultations)
  }, [organization, completedConsultations])

  const columns = useMemo(
    () => [
      {
        Header: 'Completed',
        accessor: (s: CompletedConsultations_consultations) => parseDate(s.completed_at),
        sortType: (a: any, b: any) => moment(a.original.createdAt).isAfter(moment(b.original.createdAt)),
        sortDescFirst: true,
      },
      {
        Header: 'Practice',
        accessor: (s: CompletedConsultations_consultations) => s,
        sortDescFirst: false,
        Cell: (s: { value: CompletedConsultations_consultations }) => (
          <p className="text-m m-0">{s.value.case.dicom_server?.organization?.display_name}</p>
        ),
      },
      {
        Header: 'STAT',
        accessor: (s: CompletedConsultations_consultations) => s.stat_type?.hours,
        sortDescFirst: true,
        Cell: (s: { value: number }) => <p className="text-m m-0">{s.value === undefined ? '' : `${s.value}hr`}</p>,
      },
      {
        Header: '# Images',
        accessor: (s: CompletedConsultations_consultations) => requestedImagesCount(s),
        sortDescFirst: true,
      },
      {
        Header: 'Revenue',
        accessor: 'price_amount',
        sortDescFirst: true,
      },
      {
        Header: 'Cost',
        accessor: 'receiving_vet_pay_amount',
        sortDescFirst: true,
      },
      {
        Header: 'Profit',
        accessor: (s: CompletedConsultations_consultations) => s.price_amount - s.receiving_vet_pay_amount,
        sortDescFirst: true,
      },
      {
        Header: 'Margin',
        accessor: (s: CompletedConsultations_consultations) =>
          Math.round((100 * (s.price_amount - s.receiving_vet_pay_amount)) / s.price_amount),
        sortDescFirst: true,
        Cell: (s: { value: number }) => <p className="text-m m-0">{s.value}%</p>,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: filteredConsultations,
      defaultColumn,
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, 'Practice') ? 2 : 1)

  const chart = (
    <ResponsiveContainer className="max-width-1200px pr-4 text-s regular" width="100%" height={250}>
      <LineChart data={chartData}>
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(t) => `$${numberWithCommas(t)}`} />
        <CartesianGrid stroke="#e9e9e9" />
        <Line type="monotone" dataKey="revenue" stroke="#28a745" />
        <Line type="monotone" dataKey="cost" stroke="#d1273f" />
        <Line strokeDasharray="5 5" type="monotone" dataKey="profit" stroke="#28a745" />
        <Tooltip formatter={(t: any) => `$${numberWithCommas(t)}`} />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  )

  const display = !isQuerying[QueryName.CompletedConsultations] && rows.length > 0

  return (
    <Col sm="12">
      <div className="d-flex align-items-end mb-2">
        <h4 className="bold m-0">Profitability</h4>

        <div className="min-width-300px ml-5">
          <OrganizationSelect
            organization={organizations.find((o) => o.id === organization?.value)}
            organizations={organizations.filter((o) =>
              completedConsultations.some((c) => c.case.dicom_server?.organization?.id === o.id)
            )}
            handleSelectOrganization={setOrganization}
          />
        </div>

        <div className="ml-4 d-flex align-items-center">
          <DatePicker
            dateFormat="MM/dd/yy"
            filterDate={(date: any) => moment() > date}
            className="react-select-styles"
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
          />

          <p className="m-0 mx-2">to</p>

          <DatePicker
            dateFormat="MM/dd/yy"
            filterDate={(date: any) => moment() > date}
            className="react-select-styles"
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
          />
        </div>
      </div>

      <p className="text--gray6 text-m font-italic m-0">Filter results by practice and time frame.</p>

      {display && (
        <div className="my-5">
          <h5 className="bold mb-4">Weekly Revenue & Costs</h5>
          {chart}
        </div>
      )}

      {isQuerying[QueryName.CompletedConsultations] ? (
        <Spinner color="primary" className="mt-2" />
      ) : (
        <div>
          <h5 className="bold mb-2">Case Revenue & Costs</h5>

          <Table cellSpacing={0} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any, idx: number) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <HeaderCell key={idx} {...column.getHeaderProps(column.getSortByToggleProps({}))} colSpan={colSpan(column)}>
                        <div className={`d-flex align-items-center`}>
                          <Header>{column.render('Header')}</Header>
                        </div>
                      </HeaderCell>
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {display && (
                <Row className="pb-3">
                  <Cell>
                    <p className="text-m bold m-0 text--gray7">TOTALS</p>
                  </Cell>
                  <Cell />
                  <Cell />
                  <Cell>
                    <p className="text-m bold m-0 text--gray7">
                      {filteredConsultations.filter((c) => c.stat_type_id).length.toLocaleString()}
                    </p>
                  </Cell>

                  <Cell>
                    <p className="text-m bold m-0 text--gray7">
                      {sumBy(filteredConsultations, (c) => requestedImagesCount(c)).toLocaleString()}
                    </p>
                  </Cell>
                  <Cell>
                    <p className="text-m bold m-0 text--gray7">
                      ${Math.round(sumBy(filteredConsultations, (c) => c.price_amount)).toLocaleString()}
                    </p>
                  </Cell>
                  <Cell>
                    <p className="text-m bold m-0 text--gray7">
                      ${Math.round(sumBy(filteredConsultations, (c) => c.receiving_vet_pay_amount)).toLocaleString()}
                    </p>
                  </Cell>
                  <Cell>
                    <p className="text-m bold m-0 text--gray7">
                      ${Math.round(sumBy(filteredConsultations, (c) => c.price_amount - c.receiving_vet_pay_amount)).toLocaleString()}
                    </p>
                  </Cell>
                  <Cell>
                    <p className="text-m bold m-0 text--gray7">
                      {Math.round(
                        (100 * sumBy(filteredConsultations, (c) => c.price_amount - c.receiving_vet_pay_amount)) /
                          sumBy(filteredConsultations, (c) => c.price_amount)
                      )}
                      %
                    </p>
                  </Cell>
                </Row>
              )}

              {rows.slice(0, MAX_ROWS).map((row: any, idx: number) => {
                prepareRow(row)

                return (
                  <Row key={idx} {...row.getRowProps()}>
                    {row.cells.map((cell: any, idx2: number) => {
                      return (
                        <NarrowCell key={idx2} {...cell.getCellProps()} colSpan={colSpan(cell)}>
                          {cell.render('Cell')}
                        </NarrowCell>
                      )
                    })}
                  </Row>
                )
              })}
            </tbody>
          </Table>

          {rows.length > MAX_ROWS && display && (
            <p className="w-100 text-center m-0 text-m text--gray7">Max {MAX_ROWS} rows displayed</p>
          )}
        </div>
      )}
    </Col>
  )
}
